import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/utente';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Corso } from 'src/app/core/corso';
import { Configuration } from 'src/app/core/configuration';

//const stripe = (<any>window).Stripe("pk_test_51Ho6b0GLGIDSxh9skmQIrAJKoMFSW4OA5DyXnkCMUWAqzgmyRk2VI5IoxwrFaoAlzAFo1xOf32YxWJEZFvzSjIUY00QP7IQWlq");
const stripe = (<any>window).Stripe("pk_live_51Ho6b0GLGIDSxh9spAjqOS7hCoC5gsPlGMFocWGucNmVm5tRNS8t7uw5E5JQbTi9vP9eSrytrlBZoyPwnDMqiVT00000rYPEsi");

@Component({
  selector: 'app-sign-up-screen',
  templateUrl: './sign-up-screen.component.html',
  styleUrls: ['./sign-up-screen.component.css']
})
export class SignUpScreenComponent implements OnInit {
  user: User = new User("");
  corsi : Corso[] = Corso.corsi;
  corso : Corso;
  selected: boolean  = false;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  signUp() {

    console.log(this.selected);
    this.user.corso = this.corso.nome;
    this.user.colore = this.corso.colore;
    if(this.selected != false){
      this.http.post<any>(Configuration.checkoutUrl, this.user, {
        headers: {
          "Content-Type": "application/json"
        }
      }).subscribe((data) => {
        console.log(data)
        console.log("success" +  data.sessionId);
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
      },(error) => {
        console.log(error);
      }) 
    }
  }


  // async sign() {

  //   // let customer = await stripe.customers.create(
  //   //   {
  //   //     email: this.user.email,
  //   //     name: this.user.nome,
  //   //     metadata: {
  //   //       matricola : this.user.matricola,
  //   //       corso : this.user.corso,
  //   //     }
  //   //   },
  //   // )

  //   // stripe.checkout.sessions.create(
  //   //   {
  //   //     success_url: 'https://localhost:4200/modifica',
  //   //     cancel_url: 'https://localhost:4200/login',
  //   //     payment_method_types: ['card'],
  //   //     line_items: [
  //   //       {
  //   //         name: 'T-shirt',
  //   //         description: 'Comfortable cotton t-shirt',
  //   //         amount: 1500,
  //   //         currency: 'eur',
  //   //         quantity: 2,
  //   //       },
  //   //     ],
  //   //   },
  //   // ).then((a) =>  {console.log(a);});


  //   // this.createCheckoutSession().subscribe((data) => {

  //   //     console.log(data)


  //   //     // .redirectToCheckout({
  //   //     //   sessionId: data.sessionId
  //   //     // })
  //   // })


  //   // then(function (data) {
  //   //   // Call Stripe.js method to redirect to the new Checkout page
  //   //   stripe
  //   //     .redirectToCheckout({
  //   //       sessionId: data.sessionId
  //   //     })
  //   //     .then(handleResult);
  //   // });

  //   // Handle any errors returned from Checkout
  //   var handleResult = function (result) {
  //     if (result.error) {
  //       // var displayError = document.getElementById("error-message");
  //       // displayError.textContent = result.error.message;
  //     }
  //   };
  // }


}
