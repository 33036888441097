import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginScreenComponent } from './screen/login-screen/login-screen.component';
import { ProfiloScreenComponent } from './screen/profilo-screen/profilo-screen.component';
import { AssociazioneScreenComponent } from './screen/associazione-screen/associazione-screen.component';
import { NewsScreenComponent } from './screen/news-screen/news-screen.component';
import { AssoradarScreenComponent } from './screen/assoradar-screen/assoradar-screen.component';
import { ModificaScreenComponent } from './screen/modifica-screen/modifica-screen.component';
import { HomescreenComponent } from './screen/homescreen/homescreen.component';
import { ChiSiamoComponent } from './screen/associazione-screen/pages/chi-siamo/chi-siamo.component';
import { LaScuolaComponent } from './screen/associazione-screen/pages/la-scuola/la-scuola.component';
import { GalleriaComponent } from './screen/associazione-screen/pages/galleria/galleria.component';
import { SostieniciComponent } from './screen/associazione-screen/pages/sostienici/sostienici.component';
import { SignUpScreenComponent } from './screen/sign-up-screen/sign-up-screen.component';
import { PaymentSuccessScreenComponent } from './screen/payment-success-screen/payment-success-screen.component';
import { ChangepasswordScreenComponent } from './screen/changepassword-screen/changepassword-screen.component';


const routes: Routes = [
  {
    path: '',
    component: HomescreenComponent,
    children: [

      { path: '', redirectTo: 'associazione', pathMatch: "full" },
      { path: 'news', component: NewsScreenComponent },
      {
        path: 'associazione', component: AssociazioneScreenComponent,
        children: [
          { path: '', redirectTo: 'chi siamo', pathMatch: "full" },
          { path: 'chi siamo', component: ChiSiamoComponent },
          { path: 'la scuola', component: LaScuolaComponent, },
          { path: 'galleria', component: GalleriaComponent },
          { path: 'sostienici', component: SostieniciComponent },
        ]
      },
      { path: 'assoradar', component: AssoradarScreenComponent },
      { path: 'assoradar/:tag', component: AssoradarScreenComponent },
      { path: 'profilo', component: ProfiloScreenComponent },
    ]
  },
  { path: 'login', component: LoginScreenComponent },
  { path: 'registrati', component: SignUpScreenComponent },
  { path: 'modifica', component: ModificaScreenComponent },
  { path: 'cambiapassword', component: ChangepasswordScreenComponent},
  { path: 'grazie/:id', component: PaymentSuccessScreenComponent},
  { path: '404', redirectTo: 'associazione'},
  { path: '**', redirectTo: 'associazione'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
