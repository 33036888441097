import { Corso } from './corso';

export class User {
    constructor(
        public nome: string,
        public password: string = "",
        public colore: number = -1,
        public corso: string = "",
        public matricola: string = "",
        public scadenza: string = "",
        public customer: string ="",
        public sub : string = "",

        public studi: string = "",
        public forza: string = "",
        public esperienze: string = "",
        public residenza: string = "",
        public citta: string = "",
        public liceo: string = "",
        public datanascita: string = "",
        public hobbies: string = "",
        public email: string = "",
        public cellulare: string = "",
        public socials: string = "") {
    }

    static getArray(rawstring: string): Array<string> {

        if(rawstring == null || rawstring == undefined){
            return []
        }

        if (rawstring.length > 0) {
            return rawstring.split(/[,;]+/)
        }

        return []
    }

    static getString(array: Array<string>): string {
        if (array.length > 0) {

            let str = "";
            for (let a = 0; a < array.length; a++) {
                str += array[a]
                if (a != array.length - 1) {
                    str += ", "
                }
            }
            return str
        }

        return ""
    }
    
    static optimizeString(rawstring: string): string {
        return User.getString(User.getArray(rawstring));
    }

    static check(str): boolean {
        if (str == null || str == undefined) {
            return false
        }
        if (str.length > 0) {
            return true
        }
        return false
    }

    static getBio(user: User) {
        let bio = ""

        if (User.check(user.residenza)) {
            bio += " Residente a " + user.residenza + "."
        }
        if (User.check(user.citta)) {
            bio += " Vive attualmente a " + user.citta + "."
        }

        if (User.check(user.studi)) {
            bio += " Studi: " + user.studi + "."
        }

        if (User.check(user.esperienze)) {
            bio += " Esperienze: " + user.esperienze + "."
        }

        if (User.check(user.hobbies)) {
            bio += " Hobbies: " + user.hobbies + "."
        }

        return bio;
    }

}