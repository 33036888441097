import { Component, OnInit } from '@angular/core';
import { NgxGalleryImage, NgxGalleryOptions, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.css']
})
export class ChiSiamoComponent implements OnInit {


  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];

  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        height: '400px',
        imageAnimation: NgxGalleryAnimation.Slide,
        imageAutoPlay: true,
        preview: true,
        fullWidth: true,
        imageArrows: false,
        imageAutoPlayInterval: 3000,
        thumbnails: false,
        previewDescription: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    for (let i = 1; i < 17; i++) {

      if(i == 11){
        continue;
      }

      this.galleryImages.push({
        small: 'assets/photos/' + i + '.jpg',
        medium: 'assets/photos/' + i + '.jpg',
        big: 'assets/photos/' + i + '.jpg',
        description: "Descrizione foto " + i,
      })
    }
  }

}
