import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-screen',
  templateUrl: './news-screen.component.html',
  styleUrls: ['./news-screen.component.css']
})
export class NewsScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
