import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/utente';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/core/configuration';
import { LogInService } from 'src/app/services/log-in.service';
import { Router } from '@angular/router';
import { Corso } from 'src/app/core/corso';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css']
})
export class LoginScreenComponent implements OnInit {

  user: User = new User("");
  corsi = Corso.corsi;

  message = "";

  constructor(private login: LogInService, private router: Router) { }

  ngOnInit() {}

  authenticate() {
    this.message = "";
    console.log(this.user);
    this.login.login(this.user).subscribe((result: {}) => {
      console.log(result)
      let sub = result['sub']
     
      if(result['user']['sub'] == "prova"){
        this.login.authenticate(result['user']);
        this.router.navigate(["/profilo"])
        return;
      } 
      
      if(sub != undefined) {
        if(sub['status'] == "active"){
          this.login.authenticate(result['user']);
          this.router.navigate(["/profilo"])
          return;
        }
      }

      this.erroreIscrizione()

    }, (error) => {
      console.log(error)
      this.message = "Si è verificato un errore nella connessione, riprova."
    })
  }

  erroreIscrizione(){
    this.message = "C'è un errore nella tua iscrizione, contatta l'associazione per risolverlo al più presto."
  }
}
