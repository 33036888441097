import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxGalleryModule } from 'ngx-gallery';
import { MatFormFieldModule, MatButtonModule, MatInputModule, MatTabsModule, MatIconModule, MatDividerModule, MatCardModule, MatDatepickerModule, MatNativeDateModule, MatSpinner, MatProgressSpinnerModule, MatChipsModule, MatToolbarModule, MatSelectModule, MAT_DATE_LOCALE, MatCheckbox, MatCheckboxModule } from '@angular/material'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenComponent } from './screen/splash-screen/splash-screen.component';
import { LoginScreenComponent } from './screen/login-screen/login-screen.component';
import { NewsScreenComponent } from './screen/news-screen/news-screen.component';
import { AssociazioneScreenComponent } from './screen/associazione-screen/associazione-screen.component';
import { AssoradarScreenComponent } from './screen/assoradar-screen/assoradar-screen.component';
import { ProfiloScreenComponent } from './screen/profilo-screen/profilo-screen.component';
import { ModificaScreenComponent } from './screen/modifica-screen/modifica-screen.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { BackBarComponent } from './components/back-bar/back-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomescreenComponent } from './screen/homescreen/homescreen.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { ChiSiamoComponent } from './screen/associazione-screen/pages/chi-siamo/chi-siamo.component';
import { LaScuolaComponent } from './screen/associazione-screen/pages/la-scuola/la-scuola.component';
import { SostieniciComponent } from './screen/associazione-screen/pages/sostienici/sostienici.component';
import { GalleriaComponent } from './screen/associazione-screen/pages/galleria/galleria.component';
import { NoLoggedComponent } from './screen/no-logged/no-logged.component';
import { ProfiloComponentComponent } from './components/profilo-component/profilo-component.component';
import * as moment from 'moment';
import { SignUpScreenComponent } from './screen/sign-up-screen/sign-up-screen.component';
import { PaymentSuccessScreenComponent } from './screen/payment-success-screen/payment-success-screen.component';
import { ChangepasswordScreenComponent } from './screen/changepassword-screen/changepassword-screen.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    LoginScreenComponent,
    NewsScreenComponent,
    AssociazioneScreenComponent,
    AssoradarScreenComponent,
    ProfiloScreenComponent,
    ModificaScreenComponent,
    NavBarComponent,
    BackBarComponent,
    HomescreenComponent,
    HeaderBarComponent,
    ChiSiamoComponent,
    LaScuolaComponent,
    SostieniciComponent,
    GalleriaComponent,
    NoLoggedComponent,
    ProfiloComponentComponent,
    SignUpScreenComponent,
    PaymentSuccessScreenComponent,
    ChangepasswordScreenComponent,
    FooterBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxGalleryModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatToolbarModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    MatDatepickerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
