import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { error } from 'util';
import { Configuration } from 'src/app/core/configuration';

@Component({
  selector: 'app-payment-success-screen',
  templateUrl: './payment-success-screen.component.html',
  styleUrls: ['./payment-success-screen.component.css']
})
export class PaymentSuccessScreenComponent implements OnInit {

  id;
  loading: boolean = true;
  error: boolean = false;
  result: string = "";

  constructor(private route: ActivatedRoute, private http: HttpClient) { }


  ngOnInit() {
    this.route
      .params
      .subscribe(v => {
        this.id = v["id"];

        this.http.post<{}>(Configuration.registraUrl,
          { "id": this.id },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }).subscribe((data) => {
            console.log(data)
            this.loading = false;
            this.error = false
            if (data['type'] == "aggiornamento") {
              this.result = "Il tuo profilo è stato aggiornato, conserva questa password: " + data['password'] + " , ed esegui il login."
            } if (data['type'] == "creazione") {
              this.result = "Hai appena creato il tuo profilo, conserva questa password: " + data['password'] + " ,  ed esegui il login."
            }
          }, (error) => {
            console.log(error)
            this.loading = false;
            this.error = true;
          })
      });
  }

}
