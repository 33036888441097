// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  loginUrl: "https://www.associazionedouhet.it/scripts/login.php",
  modificaUrl: "https://www.associazionedouhet.it/scripts/updateNew.php",
  cercaUrl: "https://www.associazionedouhet.it/scripts/search.php",
  matricolaUrl : "https://www.associazionedouhet.it/scripts/matricola.php",
  changepassword : "https://www.associazionedouhet.it/scripts/changepassword.php",
  registraUrl: "https://www.associazionedouhet.it/scripts/registra.php",
  checkoutUrl: "https://www.associazionedouhet.it/scripts/checkout.php"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
