import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.css']
})
export class GalleriaComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];

  constructor() {
  }

  ngOnInit() {
        this.galleryOptions = [
        {
            width: '600px',
            height: '400px',
            thumbnailsColumns: 4,
            thumbnailsRows: 2,
            imageAnimation: NgxGalleryAnimation.Slide,
            imageAutoPlay: true
        },
        // max-width 800
        {
            breakpoint: 800,
            width: '100%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 20,
            thumbnailsMargin: 20,
            thumbnailMargin: 20,
            thumbnailsRows: 2,
        },
        // max-width 400
        {
            breakpoint: 400,
            preview: false
        }
    ];

    for(let i = 1; i < 17 ; i++){

        if(i== 9){
            continue
        }

        this.galleryImages.push({
            small: 'assets/photos/' + i + '.jpg',
            medium: 'assets/photos/' + i + '.jpg',
            big: 'assets/photos/' + i + '.jpg'
        })
    }

  }

}
