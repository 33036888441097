import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-logged',
  templateUrl: './no-logged.component.html',
  styleUrls: ['./no-logged.component.css']
})
export class NoLoggedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
