

export class Corso {
    constructor(public urlimg: string, public nome: string, public data: string, public motto: string, public colore: number) {
    }

    static corsi = [
        new Corso("assets/corsi/1.jpg", "Astro", "2006-2009", "Multi radii, una lux", 0),
        new Corso("assets/corsi/2.jpg", "Bora", "2007-2010", "Venti vigor, animi rigor", 1),
        new Corso("assets/corsi/3.jpg", "Crono", "2008-2011", "Tempus edax, firma virtus", 2),
        new Corso("assets/corsi/4.jpg", "Dardo", "2009-2012", "Tela iacta, sidera capta", 3),

        new Corso("assets/corsi/5.jpg", "Espero", "2010-2013", "Primi coeli, ante omnia", 0),
        new Corso("assets/corsi/6.png", "Fides", "2011-2014", "Fulgor tonat, fervor resonat", 1),
        new Corso("assets/corsi/7.jpg", "Ghibli", "2012-2015", "Primae favillae fati lucentis", 2),
        new Corso("assets/corsi/8.jpg", "Iris", "2013-2016", "Orti tempestate, moti integritate", 3),


        new Corso("assets/corsi/9.jpg", "Lyra", "2014-2017", "Fidium cantus, erus sortis", 0),
        new Corso("assets/corsi/10.jpg", "Mito", "2015-2018", "Animo pugnamus, ingenio dominamus", 1),
        new Corso("assets/corsi/11.jpg", "Nadir", "2016-2019", "Aestus nitor lumen noctem", 2),
        new Corso("assets/corsi/12.png", "Omega", "2017-2020", "Corde ardentes, finem frangentes",3),
        
        new Corso("assets/corsi/13.png", "Perseo", "2018-2021", "Mente tenax via caelestem", 0),
        new Corso("assets/corsi/14.jpg", "Rigel", "2019-2022", "Honeste vivimus, honestius vincimus", 1),
        new Corso("assets/corsi/15.png", "Sirio", "2020-2023", "Splendor visus, signum potentiae", 2),
        new Corso("assets/corsi/16.jpg", "Taurus", "2021-2024", "Alis passis, viis factis", 3),

        new Corso("assets/corsi/17.jpg", "Ursa", "2022-2025", "Spes noctis rector caeli", 0),
        new Corso("assets/corsi/18.jpg", "Vega", "2023-2026", "Iudex coelis, lumen moris", 1)

      

    ];
}
