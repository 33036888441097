import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // if (this.userStatus.isLogged()) {} else {
    //   setTimeout(() => {
    //     this.router.navigate(['login']);
    //   }, 2500);
  }

}
