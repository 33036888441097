import { Component, OnInit } from '@angular/core';
import { User } from './core/utente';
import { LogInService } from './services/log-in.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'AssociazioneExAllieviDouhet';
  loading = true

  constructor(private login: LogInService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private http: HttpClient) {
    iconRegistry.addSvgIcon(
      'logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/logo.svg'));
    iconRegistry.addSvgIcon(
      'titolo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/titolo.svg'));
    iconRegistry.addSvgIcon(
      'titolofira',
      sanitizer.bypassSecurityTrustResourceUrl('assets/titolofira.svg'));
    iconRegistry.addSvgIcon(
        'instagram',
        sanitizer.bypassSecurityTrustResourceUrl('assets/instagram.svg'));
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/facebook.svg'));
      iconRegistry.addSvgIcon(
        'linkedin',
        sanitizer.bypassSecurityTrustResourceUrl('assets/linkedin.svg'));
  

  }

  ngOnInit(): void {

    let localUser: User = JSON.parse(localStorage.getItem("user"));

    if (localUser != null) {
      this.login.login(localUser).subscribe((result) => {
        this.stopLoading()

        let sub = result['sub']

        if (result['user']['sub'] == "prova") {
          this.login.authenticate(result['user']);
        }

        if (sub != undefined) {
          if (sub['status'] == "active") {
            this.login.authenticate(result['user']);
          } else {

          }
        }
      }, (error: Error) => {

        this.stopLoading()
        console.log(error.message);
      })

    } else {
      this.stopLoading()
    }


  }

  stopLoading() {
    setTimeout(() => {
      this.loading = false
    }, 1000);
  }
}
