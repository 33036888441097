import { Injectable } from '@angular/core';
import { User } from '../core/utente';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../core/configuration';

@Injectable({
  providedIn: 'root'
})
export class LogInService {

  user : User;
  logged: boolean = false;

  constructor(private http: HttpClient) { }


  login(user: User): Observable<Array<User>> {
    return this.http.post<Array<User>>(Configuration.loginUrl, user)
  }

  authenticate(user: User)  {
    this.user = user;
    this.logged = true;
    localStorage.setItem("user",JSON.stringify(this.user))
  }
}
