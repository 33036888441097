
import {environment} from '../../environments/environment'

export class Configuration {
    static loginUrl : string = environment.loginUrl;
    static modificaUrl : string = environment.modificaUrl;
    static cercaUrl : string = environment.cercaUrl;
    static cambiapassword : string = environment.changepassword;
    static registraUrl : string = environment.registraUrl;
    static checkoutUrl : string = environment.checkoutUrl;
}