import { Component, OnInit } from '@angular/core';
import { LogInService } from 'src/app/services/log-in.service';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/core/configuration';
import { User } from 'src/app/core/utente';
import { error } from 'util';
import { ActivatedRoute } from '@angular/router'
import { Route } from '@angular/compiler/src/core';
import { SearchObject } from 'src/app/core/searchObject';

@Component({
  selector: 'app-assoradar-screen',
  templateUrl: './assoradar-screen.component.html',
  styleUrls: ['./assoradar-screen.component.css']
})
export class AssoradarScreenComponent implements OnInit {

  searchTag = "";
  searching = false;
  noResult = false;
  result: Array<User>;
  showUser = false;
  userToShow: User;

  constructor(public login: LogInService, private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    this.showUser = false
    this.route
      .params
      .subscribe(v => {

        this.showUser = false
        this.searchTag = v["tag"]
        console.log(this.searchTag)
        if (this.searchTag != undefined) {
          this.cerca()
        }
      });
  }

  getBio(user: User): string {
    return User.getBio(user)
  }

  cerca() {
    console.log(this.searchTag);
    let searchObj: SearchObject = new SearchObject(this.searchTag, this.login.user)
    this.searching = true
    this.noResult = false

    console.log(searchObj);
    this.http.post<any>(Configuration.cercaUrl, searchObj).subscribe((result) => {
      console.log(result)
      if (result == null) {
        this.searching = false
        this.noResult = true
      }
  
      let users = result["users"];

      if (users == null || users.length <= 0) {
        this.searching = false
        this.noResult = true
      } else {
        console.log(users)
        this.result = users
        this.searching = false
        this.noResult = false
      }
    }, (error: Error) => {
      console.log(error);
      this.searching = false
      this.noResult = false
    })
  }

  show(user: User) {
    this.userToShow = user
    console.log(this.userToShow)
    this.showUser = true
  }

  back() {
    this.userToShow = null
    this.showUser = false
  }
}
