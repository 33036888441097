import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/core/utente';
import { LogInService } from 'src/app/services/log-in.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profilo-component',
  templateUrl: './profilo-component.component.html',
  styleUrls: ['./profilo-component.component.css']
})
export class ProfiloComponentComponent implements OnInit {

  @Input() user : User;

  myProfilo: boolean = false;

  constructor(private login: LogInService, private router : Router) { }

  ngOnInit() {
    if(this.user == this.login.user){
      this.myProfilo = true;
    }
  }

  getBio(): string{
    return User.getBio(this.user)
  }

  getArray(str : string) : Array<string>{
    return User.getArray(str)
  }

  search(str : string) {
    this.router.navigate(["assoradar",str])
  }
}
