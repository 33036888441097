import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/core/utente';
import { LogInService } from 'src/app/services/log-in.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/core/configuration';

@Component({
  selector: 'app-modifica-screen',
  templateUrl: './modifica-screen.component.html',
  styleUrls: ['./modifica-screen.component.css']
})
export class ModificaScreenComponent implements OnInit {

  public user : User
  public newDate: Date
  startDate = new Date(1995, 0, 1);

  constructor(private router: Router, public login : LogInService,private http : HttpClient) {
  }

  ngOnInit() {
    if(this.login.logged){
      this.user = JSON.parse(JSON.stringify(this.login.user))
      this.newDate = moment(this.user.datanascita,"DD-MM-YYYY").toDate()
    }
  }

  modifica() {

    this.user.citta = User.optimizeString(this.user.citta)
    this.user.residenza = User.optimizeString(this.user.residenza)
    this.user.studi = User.optimizeString(this.user.studi)
    this.user.esperienze = User.optimizeString(this.user.esperienze)
    this.user.hobbies = User.optimizeString(this.user.hobbies)
    this.user.email = User.optimizeString(this.user.email)
    this.user.cellulare = User.optimizeString(this.user.cellulare)
    this.user.socials = User.optimizeString(this.user.socials)
    this.user.datanascita = moment(this.newDate).format('l'); 

    console.log(this.user)
    this.http.post<any>(Configuration.modificaUrl,this.user).subscribe((result : any) => {
      console.log(result)

      if(result['result'] == true){
        this.login.authenticate(this.user)
        this.router.navigate(['/profilo'])
      }
    },(error : Error) => {
      console.error("errore");
      console.error(error.message);
    })
  }

}
