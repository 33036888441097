import { User } from './utente';


export class SearchObject {
    constructor(
        private searchTag: string,
        private user: User
    ) {

    }
}