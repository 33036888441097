import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/utente';
import { Router } from '@angular/router';
import { LogInService } from 'src/app/services/log-in.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Configuration } from 'src/app/core/configuration';
import { Location } from '@angular/common';
import { PasswordBody } from 'src/app/core/body';


@Component({
  selector: 'app-changepassword-screen',
  templateUrl: './changepassword-screen.component.html',
  styleUrls: ['./changepassword-screen.component.css']
})

export class ChangepasswordScreenComponent implements OnInit {
  
  public body = new PasswordBody();
  error = false;

  constructor(private router: Router, public login : LogInService,private http : HttpClient) {
  }

  ngOnInit() {
    if(this.login.logged){
      this.body.user = JSON.parse(JSON.stringify(this.login.user))
      this.body.user.password = "";
      this.body.password = "";
    }
  }

  modifica() {
    this.error = false;
    this.http.post<any>(Configuration.cambiapassword,this.body).subscribe((result : any) => {
      console.log(result)

      if(result['login'] == false){
        this.error = true;
      }else if(result['result'] == true){
        this.login.authenticate(this.body.user)
        this.router.navigate(['/profilo'])
      } else {
        this.error = true;
      }
    },(error : Error) => {
      this.error = true;
      console.error("errore");
      console.error(error.message);
    })
  }

}
