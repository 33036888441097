import { Component, OnInit } from '@angular/core';
import { LogInService } from 'src/app/services/log-in.service';

@Component({
  selector: 'app-profilo-screen',
  templateUrl: './profilo-screen.component.html',
  styleUrls: ['./profilo-screen.component.css']
})
export class ProfiloScreenComponent implements OnInit {

  constructor(public login: LogInService) { }

  ngOnInit() {
  }

}
