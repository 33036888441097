import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-associazione-screen',
  templateUrl: './associazione-screen.component.html',
  styleUrls: ['./associazione-screen.component.css']
})
export class AssociazioneScreenComponent implements OnInit {

  tabs = ["chi siamo", "la scuola", "galleria", "sostienici"];
  
  constructor() { }

  ngOnInit() {
  }

}
