import { Component, OnInit } from '@angular/core';
import { Corso } from 'src/app/core/corso';

@Component({
  selector: 'app-la-scuola',
  templateUrl: './la-scuola.component.html',
  styleUrls: ['./la-scuola.component.css']
})
export class LaScuolaComponent implements OnInit {

  public corsi = Corso.corsi;

  constructor() { }

  ngOnInit() {
  }

}
